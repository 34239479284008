<template>
  <v-main>
    <v-container fluid class="mt-1">
      <v-row justify="center" align="center">
        <v-col md="12" style="background-color: #f5f5f5">
          <v-icon color="red">mdi-book-information-variant</v-icon
          ><span class="font-weight-bold mb-3">TOUR</span>
          <v-container>
            <v-row justify="center" align="center">
              <v-col md="3">
                <v-chip color="#FFCDD2" class="mt-n15"
                  ><span style="color: red">Explore the World</span></v-chip
                >
                <p style="font-size: 25px; font-weight: bold" class="mb-0">
                  <span style="color: red">Find</span> your
                </p>
                <p style="font-size: 25px; font-weight: bold" class="mb-0 mt-0">
                  Dream<v-icon class="black ml-1" color="white"
                    >mdi-camera-outline</v-icon
                  >
                </p>

                <p style="font-size: 25px; font-weight: bold">Destination</p>
                <p style="font-size: 15px" class="mt-4">
                  Turn your travel dreams into unforgettable realities. Embark
                  on an extraordinary journey filled with discovery, excitement,
                  and relaxation as you explore some of the world's most
                  captivating destinations.
                </p>
                <p style="font-size: 10px" class="mt-9">
                  (Developed By Sonakshi)
                </p>
              </v-col>
              <v-col md="2">
                <v-img
                  :src="require('../assets/travele.jpg')"
                  contain
                  height="300"
                />
              </v-col>
              <v-col
                md="5"
                sm="11"
                cols="10"
                style="background-color: #eeeeee; border-radius: 12px"
              >
                <p
                  class="text-center mb-n2"
                  style="font-size: 100%; font-weight: 600"
                >
                  What are you waiting for
                </p>
                <br />
                <p style="color: red; font-weight: bold; font-size: 15px" class="text-center mt-n3">
                  Get Ready
                  <v-icon small color="black">mdi-run-fast</v-icon>
                </p>
                <br />
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="formData.baseLocation"
                          outlined
                          label="Base Location"
                          placeholder="Base Location"
                          :rules="[rules.required, rules.noNumbers]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="formData.placeToVisit"
                          outlined
                          label="Place to Visit"
                          placeholder="Place to Visit"
                          :rules="[rules.required, rules.noNumbers]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="formData.budgetToSpend"
                          outlined
                          label="Budget(INR)"
                          placeholder="Budget"
                          type="number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="formData.numberOfDays"
                          outlined
                          label="Number of Days"
                          type="number"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="formData.foodPreference"
                          outlined
                          :items="foodPreferences"
                          label="Food Preference"
                          placeholder="Food Preference"
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                      <v-col cols="12" class="text-center">
                        <v-btn
                          type="submit"
                          @click="getData()"
                          :disabled="!valid || loading"
                          style="
                            background-color: red;
                            border-radius: 12px;
                            color: white;
                          "
                          >Plan A Trip <v-icon>mdi-chevron-right</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-overlay :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" v-if="iteneryData">
              <v-col
                md="10"
                class="pa-10 mt-4"
                style="border-radius: 12px; background-color: #e1f5fe"
              >
                <div v-html="iteneryData"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import showdown from "showdown";
import { rules } from "@/util/rules";

import {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} from "@google/generative-ai";
export default {
  name: "App",
  data: () => ({
    formData: {
      baseLocation: "",
      placeToVisit: "",
      budgetToSpend: "",
      numberOfDays: "",
      foodPreference: "",
    },
    rules: rules,
    foodPreferences: ["Vegetarian", "Non-Vegetarian", "Vegan"],
    loading: false,
    iteneryData: "",
    valid: true,
  }),
  methods: {
    async getData() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const MODEL_NAME = "gemini-1.0-pro";
        const API_KEY = process.env.VUE_APP_KEY;
        const genAI = new GoogleGenerativeAI(API_KEY);
        const model = genAI.getGenerativeModel({ model: MODEL_NAME });

        const generationConfig = {
          temperature: 0.9,
          topK: 1,
          topP: 1,
          maxOutputTokens: 2048,
        };

        const safetySettings = [
          {
            category: HarmCategory.HARM_CATEGORY_HARASSMENT,
            threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
          },
          {
            category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
            threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
          },
          {
            category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
            threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
          },
          {
            category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
            threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
          },
        ];

        const parts = [
          {
            text: `Create an itinerary for ${this.formData.baseLocation} to ${this.formData.placeToVisit} for ${this.formData.numberOfDays} days trip in a ${this.formData.budgetToSpend} Indian Rupees  budget and Food Preference is ${this.formData.foodPreference}`,
          },
        ];
        const result = await model.generateContent({
          contents: [{ role: "user", parts }],
          generationConfig,
          safetySettings,
        });

        const response = result.response;
        let converter = new showdown.Converter();
        this.iteneryData = await converter.makeHtml(response.text());
        console.log(this.iteneryData, "iteneryData");
        this.loading = false;
        this.$refs.form.reset();
      } else {
        this.loading = false;
        alert("Data is Not Valid");
      }
    },
  },
};
</script>
